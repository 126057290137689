export const arrayToObject = (array: {[key: string]: any}[], key: string) =>
   array.reduce((obj, item) => {
     obj[item[key]] = item
     return obj
   }, {})

export const concatNarrays = (arr: string[][]) => {
    const newArr = arr.reduce((prev, next) =>  {
       return prev.concat(next);
    });
    return newArr;
}

export function formatLink(link: string){
   if (link.substr(0,4) === 'http'){
     return link
   } else {
     return `http://${link}`
   }
 }