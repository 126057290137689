import React from "react";
import { DeepMap, FieldError } from "react-hook-form";
import { formInputInfo, requiredType } from "../types/types";

export const sortByGroup = (arr: formInputInfo[]) => {
  return arr.sort((a, b) => a.group - b.group);
};

export function focusOnErrors(
  errors: DeepMap<Record<string, any>, FieldError>
) {
  for (const key of Object.keys(errors)) {
    return errors[key].ref.focus();
  }
}

export function getProductSummary(
  questions: formInputInfo[],
  formState: { [key: string]: string | string[] }
): JSX.Element {
  const productItem = questions.find((item) => item.product);
  if (productItem === undefined) {
    return null;
  }
  if (productItem.product.multiplier === "Self") {
    const summaryStr = questions
      .filter((item) => item.product)
      .reduce((acc, curr) => {
        const productVal = formState[curr.id];
        const productStr = typeof productVal === "string" ? productVal : "";
        const tax = curr.product.tax ? getTax(curr.product.tax) : "1";
        const productStrArr = productStr.replace(",", ".").split(" ");
        return (
          acc + Number(productStrArr[productStrArr.length - 1]) * Number(tax)
        );
      }, 0);
    return (
      <div>
        <span className="text-brand-dark_blue">{summaryStr}€</span>
        <br></br>
        <span className="italic text-brand-dark_blue normal-case">
          Sis. Alv. {productItem.product.tax}%
        </span>
      </div>
    );
  } else {
    const productVal = formState[productItem.id];
    const productStr = typeof productVal === "string" ? productVal : "";
    const multiplierVal = formState[productItem.product.multiplier];
    const multiplier = typeof multiplierVal == "string" ? multiplierVal : 1;
    const tax = productItem.product.tax ? getTax(productItem.product.tax) : "1";
    const productStrArr = productStr.replace(",", ".").split(" ");
    const summaryStr = (
      Number(multiplier) *
      Number(productStrArr[productStrArr.length - 1]) *
      Number(tax)
    ).toString();
    return (
      <div>
        <span className="text-brand-dark_blue">{summaryStr}€</span>
        <br></br>
        <span className="italic text-brand-dark_blue normal-case">
          Sis. Alv. {productItem.product.tax}%
        </span>
      </div>
    );
  }
}

function getTax(tax: string) {
  if (Number(tax) > 0) {
    return (Number(tax) / 100 + 1).toString().replace(",", ".");
  }
  if (Number(tax) === 0) {
    return "1";
  }
  return tax.replace(",", ".");
}

export function returnRequiredRule(
  obj: { type: requiredType; key?: string; value?: string },
  register: any,
  formState: { [key: string]: string | string[] }
) {
  if (obj.key && obj.value) {
    if (formState[obj.key] !== obj.value) {
      return register();
    }
  }
  if (obj.type === "email") {
    return register({
      required: `any`,
      pattern: {
        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, //eslint-disable-line
        message: `email`,
      },
    });
  } else if (obj.type === "phone") {
    return register({
      required: `any`,
      pattern: {
        value: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im, //eslint-disable-line
        message: `phone`,
      },
    });
  } else if (obj.type === "any") {
    return register({ required: `any` });
  } else {
    return register();
  }
}
