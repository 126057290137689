import React from "react";

interface Props {
  divider: string;
  repeatable: boolean;
  label?: string;
  style?: string;
}

export const RepeatableHeader: React.FC<Props> = ({
  divider,
  repeatable,
  label,
  style,
}) => {
  if (!divider || !repeatable) {
    return null;
  }

  const dividerHeaderStyles = style
    ? style
    : "m-2 uppercase font-bold text-lg text-brand-blue";

  return <span className={`${dividerHeaderStyles}`}>{label}</span>;
};
