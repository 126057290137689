import React from "react";
import { RepeatableButtons } from "./buttons/RepeatableButtons";
import { ErrorDiv } from "./divs/ErrorDiv";
import { RepeatableHeader } from "./divs/RepatableHeader";
import { LabelContainer } from "./labels/LabelContainer";

interface FormRadioProps {
  id: string;
  options?: string[];
  question: string;
  divider?: string;
  divStyle?: string;
  inputStyle?: string;
  labelStyle?: string;
  optionLabelStyle?: string;
  dividerHeaderStyle?: string;
  repeatable?: boolean;
  containerStyle?: string;
  register?: any;
  errors?: Record<string, Object>;
  link?: string | boolean;
  cursiveLabel?: string;
  repeatableHeader?: string;
  checked: string;
  repeatableKey?: { key: string; last: boolean; amount: number };
  index?: number;
  overlayId?: string;
  overlayToggle?: (id: string) => void;
  validate: (id: string) => void;
  changeAmount?: (key: string, direction: number) => void;
  handleInputChange: (
    event: React.ChangeEvent<HTMLElement>,
    id: string,
    type?: string
  ) => void;
}

export const FormRadio: React.FC<FormRadioProps> = ({
  id,
  options,
  handleInputChange,
  question,
  divider,
  inputStyle,
  divStyle,
  errors,
  link,
  repeatableHeader,
  repeatableKey,
  validate,
  overlayId,
  overlayToggle,
  labelStyle,
  optionLabelStyle,
  dividerHeaderStyle,
  repeatable,
  containerStyle,
  register,
  cursiveLabel,
  checked,
  changeAmount,
  index,
}) => {
  const styles = inputStyle
    ? inputStyle
    : "form-radio rounded-lg transition transform duration-500 ease-in-out hover:scale-105";

  const divStyles = divStyle
    ? divStyle
    : "flex flex-col mb-4 xl:w-11/12 xl:m-auto xl:pt-2";

  const optionLabelStyles = optionLabelStyle
    ? optionLabelStyle
    : "text-lg text-brand-dark_blue";

  const participantId = repeatable ? id.split("_")[1] : ""; //expected id is 'BLABLABLA_2' => 2

  const dividerHeaderStyles = dividerHeaderStyle
    ? dividerHeaderStyle
    : "m-2 uppercase font-bold text-lg text-brand-blue";

  const dividerStyles = divider ? divider : "";
  const containerStyles = containerStyle ? containerStyle : "";

  const optionsDiv = "xl:flex xl:flex-wrap xl:pb-5 xl:pt-2 pl-8 xl:pl-6";

  return (
    <div className={`${divStyles} ${dividerStyles} ${containerStyles}`}>
      <RepeatableHeader
        divider={divider}
        repeatable={repeatable}
        label={`${repeatableHeader} ${participantId}`}
        style={dividerHeaderStyles}
      />
      <LabelContainer
        link={link}
        question={question}
        labelStyle={labelStyle}
        register={register}
        cursiveLabel={cursiveLabel}
        overlayToggle={overlayToggle}
        overlayId={overlayId}
      />
      <fieldset tabIndex={index}>
        <div className={optionsDiv}>
          {options?.map((option, i) => (
            <div
              key={option}
              className={`w-full xl:w-1/3 pl-4 pt-3 xl:pt-0 ${styles}`}
            >
              <label
                htmlFor={`${id}:${option}`}
                className={`${optionLabelStyles} multiSelect`}
                tabIndex={i}
              >
                <input
                  name={id}
                  value={option}
                  type="radio"
                  id={`${id}:${option}`}
                  onChange={(e) => handleInputChange(e, id)}
                  ref={register}
                  checked={checked === option}
                  onBlur={validate ? () => validate(id) : null}
                />
                <span className="radiocircle"></span>
                {option}
              </label>
            </div>
          ))}
        </div>
      </fieldset>
      <ErrorDiv errors={errors} id={id} />
      <RepeatableButtons
        repeatableKey={repeatableKey}
        changeAmount={changeAmount}
        repeatableHeader={repeatableHeader}
      />
    </div>
  );
};

export default FormRadio;
