import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import allActions from "../actions/allActions";
import FormContainer from "../components/form/FormContainer";
import { Loading } from "../components/utils/Loading";
import { FormParams, Summary } from "../types/types";
import * as config from "../services/config";
import { Message } from "../components/utils/Message";
import { AlertDialog } from "../components/modals/AlertDialog";
import { Logo } from "../components/utils/Logo";
import { Toggle } from "../components/utils/Toggle";
import { Summary as SummaryComponent } from "../components/summary/Summary";
import ConsoleHelper from "../utils/logger";
import { useCallback } from "react";
import { selectFormPageData } from "../reducers/formReducer";

export const FormPage: React.FC = () => {
  const { id, domain } = useParams<FormParams>();
  const formName = domain ? `${domain}/${id}` : id;
  const dispatch = useDispatch();
  const {
    items,
    input,
    logoUrl,
    message,
    subEventId,
    overview,
    showRegistration,
    registrationFull,
    maintenanceMode,
    maintenanceModeEndTime,
    initLoading,
    pageDescription,
    pageHeader,
    pageRegistrationFullText,
    pageRegistrationFullHeader,
    pageregistrationNotYetOpenText,
    pageThankYouHeader,
    pageThankYouText,
    pageregistrationNotYetOpenHeader,
    uuids,
    expiredAmount,
    registered,
  } = useSelector((state) => selectFormPageData(state, formName));
  const formStyle = "flex flex-col bg-brand-white lg:p-8 pt-4";
  const rereserveToggle = "RERESERVE_TOGGLE";
  const logoUrlFull = `${config.localUrl}${logoUrl}`;
  const buttonInfo = {
    style: "bg-blue-400 hover:bg-brand-blue text-brand-white",
    format_id: "EventRegistration.confirm_registration",
  };

  const sendData = () => {
    if (uuids.length > 0) {
      const otherQuestions = Object.keys(input).map((input) => {
        const id = input.match(/(_\d+)$/) ? input.split("_")[0] : input;
        const item = items.find((i) => i.id === id);
        return { ...item, id: input };
      });
      const otherAnswers = otherQuestions
        .filter((item) => !item.person)
        .map((item) => {
          const answer = item.type === "accept" ? "YES" : input[item.id];
          return answer
            ? {
                id: item.id,
                question: item.question,
                answer,
                ...(item.dbKeys && { dbKeys: item.dbKeys }),
                ...(item.mailKeys && { mailKeys: item.mailKeys }),
              }
            : null;
        })
        .filter((a) => a);

      const allPersonQuestions = items.filter((item) => item.person);
      const answers = uuids.reduce((obj, uuid, index) => {
        const personsAnswers = allPersonQuestions
          .map((a) => {
            const id = index === 0 ? a.id : `${a.id}_${index + 1}`;
            const answer = input[id];
            return answer
              ? {
                  id: a.id,
                  question: a.question,
                  answer,
                  person: true,
                  ...(a.dbKeys && { dbKeys: a.dbKeys }),
                  ...(a.mailKeys && { mailKeys: a.mailKeys }),
                }
              : null;
          })
          .filter((a) => a);
        return {
          ...obj,
          [uuid]: otherAnswers.concat(personsAnswers),
        };
      }, {});

      const objectToSend: Summary = {
        subEventId: subEventId,
        answers,
      };

      ConsoleHelper("object to send", objectToSend);
      dispatch(
        allActions.registrationActions.startRegistration(objectToSend, formName)
      );
    }
  };

  function reserve(event: any, attribute?: string) {
    const amount = typeof event === "number" ? event : event.target.value;
    const key = typeof event === "number" ? attribute : event.target.id;
    if (Number(amount) !== 0) {
      if (Number(amount) > uuids.length) {
        const reserveAmount = (Number(amount) - uuids.length).toString();
        dispatch(
          allActions.registrationActions.reserve(
            reserveAmount,
            subEventId,
            key,
            amount,
            formName
          )
        );
      } else if (Number(amount) < uuids.length) {
        const copyOfUuids = [...uuids];
        const diff = copyOfUuids.length - Number(amount);
        const deleted = copyOfUuids.splice(amount, diff);
        dispatch(
          allActions.registrationActions.unreserve(
            deleted,
            subEventId,
            key,
            amount,
            formName
          )
        );
      }
    }
  }

  const rereserve = () => {
    dispatch(
      allActions.registrationActions.rereserve(expiredAmount, subEventId)
    );
    dispatch(allActions.toggleActions.hideToggle(rereserveToggle));
  };

  const removeMessage = () => {
    dispatch(allActions.formActions.removeMessage());
  };

  const getForm = useCallback(() => {
    dispatch(allActions.formActions.startFormFetch(formName));
  }, [dispatch, formName]);

  useEffect(() => {
    getForm();
  }, [getForm]);

  return (
    <div className="flex flex-col content-center h-full max-h-11/12 xl:w-1/2 w-11/12 m-auto">
      {initLoading ? (
        <Loading />
      ) : message ? (
        <>
          <Logo url={logoUrl} />
          <AlertDialog message={message} alertAction={removeMessage} />
        </>
      ) : showRegistration ? (
        <>
          <Logo url={logoUrlFull} />
          <FormContainer
            title={pageHeader}
            description={pageDescription}
            list={items}
            formStyle={formStyle}
            buttonInfo={buttonInfo}
            formName={formName}
            formAction={sendData}
            formReserve={reserve}
            reserveToggle={rereserveToggle}
          />
          <Toggle id={rereserveToggle}>
            <AlertDialog
              message={"RESERVATION EXPIRE"}
              alertAction={rereserve}
              styles={
                "fixed xl:ml-32 xl:w-1/3 lg:ml-10 ml-4 w-5/6 max-h-1/2 my-40 shadow-xl border border-gray-400 z-50 bg-brand-white"
              }
            />
          </Toggle>
        </>
      ) : registered ? (
        <>
          <Logo url={logoUrl} />
          <Message
            messageHeader={pageThankYouHeader}
            message={pageThankYouText}
            additionalContent={true}
          />
          <SummaryComponent data={overview} />
        </>
      ) : registrationFull ? (
        <>
          <Logo url={logoUrl} />
          <Message
            messageHeader={pageRegistrationFullHeader}
            message={pageRegistrationFullText}
            additionalContent={false}
          />
        </>
      ) : maintenanceMode ? (
        <>
          <Logo url={logoUrl} />
          <Message
            messageHeader={"Maintenance"}
            message={
              maintenanceModeEndTime
                ? `Maintenance planned to end ${maintenanceModeEndTime}`
                : `Site is down for maintenance for a bit`
            }
            additionalContent={false}
          />
        </>
      ) : (
        <>
          <Logo url={logoUrl} />
          <Message
            messageHeader={pageregistrationNotYetOpenHeader}
            message={pageregistrationNotYetOpenText}
            additionalContent={false}
          />
        </>
      )}
    </div>
  );
};

export default FormPage;
