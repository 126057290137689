import React from 'react';
import { Navigation } from './navigation/Navigation'
import { useSelector } from 'react-redux'
import { RootState } from './reducers/combineReducer'
import { ErrorFetching } from './components/modals/ErrorFetching';
import { ErrorState } from './types/types';
import { Logo } from './components/utils/Logo';
import { localUrl, defaultLogoUrl, defaultBg } from './services/config'

export const App: React.FC = () => {

  const { message } = useSelector((state: RootState) => state['errorState']) as unknown as ErrorState

  return (
       message ? 
       <div 
        className='w-screen h-screen'
        style={{backgroundColor: defaultBg}}>
          <div className='xl:w-1/2 w-11/12 m-auto h-full'>
            <Logo url={`${localUrl}${defaultLogoUrl}`}/>
            <ErrorFetching message={message} /> 
          </div>
      </div>
      : 
      <Navigation /> 
  );
}

export default App;
