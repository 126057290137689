import { Summary } from "../types/types"

const reserve = (value: string, subEventId: string, key: string, val: string, form: string) => {
    const amount = !isNaN(Number(value)) ? Number(value) : 0
    return {
        type: 'START_RESERVE_X',
        payload: {
            amount, 
            subEventId, 
            key, 
            value: val, 
            form}
    }
}

const rereserve = (amount: number, subEventId: string) => {
    return {
        type: 'START_RERESERVE',
        payload: {amount, subEventId}
    }
}


const unreserve = (deleted: string[], subEventId: string, key: string, value: string, form: string) => {
    return {
        type: 'START_UNRESERVE',
        payload: {
            deleted, 
            subEventId, 
            key, 
            value, 
            form}
    }
}


const startRegistration = (data: Summary, form: string) => {
    return {
        type: 'CREATEREGISRTATIONSUMMARY',
        payload: {data, form}
    }
}

const register = (data: Summary) => {
    return {
        type: 'START_REGISTRATION',
        payload: data
    }
}


const removeUuids = (reservationToggle: string) => {
    return {
        type: 'REMOVEUUIDS',
        payload: reservationToggle
    }
}



const registrationActions = {
    reserve,
    unreserve,
    register,
    removeUuids,
    rereserve,
    startRegistration
}

export default registrationActions