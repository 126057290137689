import React from 'react';

interface ErrorFetchingProps {
    message: string
    styles?: string
}

export const ErrorFetching: React.FC<ErrorFetchingProps> = ({ message, styles }) => {

  const style = styles !== undefined ? styles : 'container'
  
  return (
    <div className={style}>
      <div className='flex flex-col bg-brand-white rounded-b pt-5 text-center'>
          <span className='text-brand-dark_blue uppercase font-bold m-4'>ERROR</span>
          <span className='text-brand-dark_blue text-center mb-4'>{message}</span>
      </div>
    </div>
  );
}

export default ErrorFetching;
