import axios from 'axios'
import * as config from './config';


const getForm = async (form: string): Promise<{data: any, status: number}> => {
  const url = `${config.baseUrl}/ereg/u/${form}`
  return await axios({url})
}


const getModificationForm = async (form: string, modificationToken: string): Promise<{data: any, status: number}> => {
  const url = `${config.baseUrl}/ereg/m/${form}/${modificationToken}`
  return await axios({url})
}


const getCancelForm = async (form: string, cancelToken: string): Promise<{data: any, status: number}> => {
  const url = `${config.baseUrl}/ereg/c/${form}/${cancelToken}`
  return await axios({url})
}


const formService = {
  getForm,
  getModificationForm,
  getCancelForm
}

export default formService
