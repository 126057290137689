import React, { useEffect, useRef, useState } from "react";
import { FormInput } from "./FormInput";
import { FormSelect } from "./FormSelect";
import { FormTextArea } from "./FormTextArea";
import { formInputInfo, buttonInfo } from "../../types/types";
import allActions from "../../actions/allActions";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import { FormRadio } from "./FormRadio";
import FormCheckBox from "./FormCheckBox";
import { RootState } from "../../reducers/combineReducer";
import { FcPrevious, FcNext } from "react-icons/fc";
import { useForm } from "react-hook-form";
import { ProgressBar } from "../utils/Progress";
import ReservationBar from "../utils/ReservationBar";
import {
  sortByGroup,
  focusOnErrors,
  getProductSummary,
  returnRequiredRule,
} from "../../utils/formFunctions";
import { selectFormItemsAndValues } from "../../reducers/formReducer";

interface FormProps {
  formAction?: (data: { [key: string]: string | string[] }) => void;
  formReserve?: (event: any, id?: string) => void;
  title?: string;
  description?: string;
  list: formInputInfo[];
  formStyle: string;
  buttonInfo: buttonInfo;
  message?: string;
  formName: string;
  reserveToggle: string;
}

export const FormContainer: React.FC<FormProps> = ({
  title,
  list,
  formStyle,
  buttonInfo,
  formAction,
  message,
  formName,
  formReserve,
  description,
  reserveToggle,
}) => {
  const { register, handleSubmit, trigger, errors, unregister } = useForm();
  const dispatch = useDispatch();
  const {
    items,
    values,
    repeatableLength,
    pages,
    currentPage,
    highestVisited,
    maxPage,
  } = useSelector((state) => selectFormItemsAndValues(state, formName));
  const reservation: {
    uuids: string[];
    reserveExpireTime: string;
    queued: boolean;
    firstAvailablePositionInQue: number;
  } = useSelector((state: RootState) => state["reservationState"]);
  const [registered, setRegistered] = useState([]);
  const pageList = items.filter((item) => item.page === currentPage);
  let prevGroup = pageList.length > 0 ? pageList[0].group : 0;
  const summary = getProductSummary(items, values);
  const formRef = useRef(null);

  const messageDiv =
    message !== "" ? (
      <div className="text-red-400 h-2 pb-4">{message}</div>
    ) : (
      <div className="invisible h-2 pb-2">{message}</div>
    );
  const titleDiv = title ? (
    <div className="text-center text-brand-dark_blue lg:m-4 font-semibold text-3xl">
      <span className="text-brand-dark_blue">{title}</span>
    </div>
  ) : null;
  const descriptionDiv = description ? (
    <div className="text-center text-brand-dark_blue lg:m-4 m-2 italic text-lg xl:pb-5">
      <span className="text-brand-dark_blue">{description}</span>
    </div>
  ) : null;
  const submitHidden =
    currentPage === maxPage
      ? "visible"
      : "invisible focus:outline-none outline-none";
  const continueHidden =
    currentPage === maxPage
      ? "invisible focus:outline-none outline-none"
      : "visible";
  const previousHidden =
    currentPage === pages[0]
      ? "invisible focus:outline-none outline-none"
      : "visible";
  const iconStyles = "cursor-pointer";
  const buttonColors =
    reservation.uuids.length > 0
      ? buttonInfo.style
      : "bg-gray-500 text-brand-white cursor-default";
  const summaryDiv =
    summary !== null ? (
      currentPage === maxPage ? (
        <div className="text-center m-4 uppercase font-semibold text-lg">
          <span className="text-brand-blue m-2">
            <FormattedMessage id="Form.Product_summary" />:
          </span>{" "}
          {summary}
        </div>
      ) : null
    ) : null;

  //TODO: 1. Pitää olla myös erilainen ehto toistoryhmille. Lisää ja vähennä nappi halutaan myös ns. ensimmäisten toistoryhmien alle.
  //Jos painaa Add, niin täytyy muuttaa myös Key valuen mukaiseksi!
  //TODO: 2. Jos viimeinen toistoryhmän kysymys on piilotettu, niin 'lisää' j poista nappula ovat piilossa.
  //TODO: 3. Lisätään € merkki product-kysymyksen jälkeen

  const handleInputChange = (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLTextAreaElement>,
    id: string,
    type?: string
  ) => {
    if (type === "array") {
      const currentValue: string[] | string = values[id];
      const newArray =
        currentValue === undefined
          ? []
          : typeof currentValue === "string"
          ? [currentValue]
          : currentValue;
      dispatch(
        allActions.formActions.addToValue(
          id,
          newArray,
          event.target.value,
          formName
        )
      );
    } else {
      dispatch(
        allActions.formActions.updateValue(id, event.target.value, formName)
      );
    }
  };

  const chooseInputElement = (
    obj: formInputInfo,
    register: any,
    errors: any,
    handlerFunction: (event: any, id: string, type?: string) => void,
    index: number,
    divider: boolean,
    changeAmount: (key: string, direction: number) => void
  ) => {
    const border = divider ? "border-t-2 border-fuchsia-600 pt-8" : "";
    const val = values[obj.id];
    const value = typeof val === "string" ? val : "";
    const valArr = Array.isArray(val) ? val : [];
    const repeatable = obj.repeatable ? true : false;
    const repeatableHeader = obj.repeatable ? obj.repeatable.header : null;
    const repeatableLast = obj.repeatable
      ? obj.repeatable.last
        ? {
            key: obj.repeatable.amount,
            last: true,
            amount: Number(values[obj.repeatable.amount]) as number,
          }
        : {
            key: obj.repeatable.amount,
            last: false,
            amount: Number(values[obj.repeatable.amount]) as number,
          }
      : { key: "", last: false, amount: 1 };
    const link = obj.link ? obj.link : false;
    const reg = obj.required
      ? returnRequiredRule(obj.required, register, values)
      : null;
    const reservation = obj.reservation ? formReserve : null;
    if (obj.type === "dropdown") {
      return (
        <FormSelect
          question={obj.question}
          key={obj.id}
          id={obj.id}
          value={value}
          options={obj.options}
          handleInputChange={handlerFunction}
          divider={border}
          register={reg}
          errors={errors}
          link={link}
          repeatableHeader={repeatableHeader}
          repeatableKey={repeatableLast}
          cursiveLabel={obj.description || null}
          changeAmount={changeAmount}
          validate={obj.required ? validate : null}
          index={index}
        />
      );
    } else if (obj.type === "textarea") {
      return (
        <FormTextArea
          question={obj.question}
          key={obj.id}
          id={obj.id}
          value={value}
          handleInputChange={handlerFunction}
          divider={border}
          register={reg}
          errors={errors}
          link={link}
          repeatableHeader={repeatableHeader}
          repeatableKey={repeatableLast}
          cursiveLabel={obj.description || null}
          changeAmount={changeAmount}
          validate={obj.required ? validate : null}
          index={index}
        />
      );
    } else if (obj.type === "radio" || obj.type === "product") {
      return (
        <FormRadio
          question={obj.question}
          key={obj.id}
          id={obj.id}
          options={obj.options}
          handleInputChange={handlerFunction}
          divider={border}
          register={reg}
          errors={errors}
          link={link}
          repeatableHeader={repeatableHeader}
          repeatableKey={repeatableLast}
          checked={value}
          cursiveLabel={obj.description || null}
          changeAmount={changeAmount}
          validate={obj.required ? validate : null}
          index={index}
        />
      );
    } else if (obj.type === "checkbox") {
      return (
        <FormCheckBox
          question={obj.question}
          key={obj.id}
          id={obj.id}
          options={obj.options}
          handleInputChange={handlerFunction}
          divider={border}
          register={reg}
          errors={errors}
          link={link}
          repeatableHeader={repeatableHeader}
          repeatableKey={repeatableLast}
          checked={valArr}
          cursiveLabel={obj.description || null}
          changeAmount={changeAmount}
          validate={obj.required ? validate : null}
          index={index}
        />
      );
    } else if (obj.type === "accept") {
      return (
        <FormCheckBox
          divStyle="flex flex-row-reverse justify-end items-center text-xs xl:w-11/12 xl:m-auto xl:text-base"
          question={obj.question}
          key={obj.id}
          id={obj.id}
          options={obj.options}
          handleInputChange={handlerFunction}
          divider={border}
          register={reg}
          errors={errors}
          link={link}
          repeatableHeader={repeatableHeader}
          repeatableKey={repeatableLast}
          checked={valArr}
          cursiveLabel={obj.description || null}
          changeAmount={changeAmount}
          validate={obj.required ? validate : null}
          index={index}
        />
      );
    } else {
      return (
        <FormInput
          question={obj.question}
          key={obj.id}
          id={obj.id}
          value={value}
          type={obj.type}
          handleInputChange={handlerFunction}
          divider={border}
          repeatable={repeatable}
          register={reg}
          errors={errors}
          link={link}
          repeatableHeader={repeatableHeader}
          repeatableKey={repeatableLast}
          cursiveLabel={obj.description || null}
          changeAmount={changeAmount}
          onBlur={reservation}
          validate={obj.required ? validate : null}
          index={index}
        />
      );
    }
  };

  const changePage = async (e: any, p: number) => {
    e.preventDefault();
    const valid = p < currentPage ? true : await trigger();
    if (valid) {
      const highest = p > highestVisited ? p : highestVisited;
      dispatch(allActions.formActions.setPage(p, highest));
    }
  };

  const changeAmount = (key: string, direction: number) => {
    const currentValue = values[key];
    const newAmount =
      typeof currentValue === "string" ? Number(currentValue) + direction : 0;
    dispatch(
      allActions.formActions.updateValue(key, newAmount.toString(), formName)
    );
    const item = list.find((i) => i.id === key);
    if (item.reservation) {
      formReserve(newAmount, item.id);
    }
  };

  function unRegisterFiltered(
    current: formInputInfo[],
    previous: formInputInfo[],
    formName: string
  ) {
    if (JSON.stringify(previous) !== JSON.stringify(current)) {
      const previousOnlyIds = previous.map((p) => p.id);
      const currentOnlyIds = current.map((c) => c.id);
      const idsToUnregister = previousOnlyIds.filter(
        (item) => !currentOnlyIds.includes(item)
      );
      for (const id of idsToUnregister) {
        unregister(id);
        dispatch(allActions.formActions.removeKey(id, formName));
      }
    }
    setRegistered(items);
  }

  function validate(id: string) {
    trigger(id);
  }

  function checkShouldReserve(filtered: formInputInfo[]) {
    const reserveIndex = filtered.findIndex((item) => item.reservation);
    if (reserveIndex === -1) {
      const reserveItem = list.find((item) => item.reservation);
      if (reserveItem) {
        return formReserve(1, reserveItem.id);
      }
      return formReserve(1);
    }
  }

  function focusToTop() {
    formRef.current.scrollIntoView();
  }

  useEffect(() => {
    unRegisterFiltered(items, registered, formName);
    checkShouldReserve(items);
  }, [repeatableLength]); //eslint-disable-line

  useEffect(() => {
    focusOnErrors(errors);
  }, [errors]);

  useEffect(() => {
    if (highestVisited > 1) {
      focusToTop();
    }
  }, [currentPage]); //eslint-disable-line

  return (
    <>
      <ReservationBar
        amount={reservation.uuids.length}
        expiration={reservation.reserveExpireTime}
        reservationToggle={reserveToggle}
        queued={reservation.queued}
        queuePosition={reservation.firstAvailablePositionInQue || null}
      />
      <div className="overflow-y-auto overflow-x-hidden scroller">
        <form
          className={`${formStyle}`}
          onSubmit={handleSubmit(formAction)}
          ref={formRef}
        >
          {currentPage === pages[0] ? (
            <>
              {titleDiv}
              {descriptionDiv}
            </>
          ) : null}

          {sortByGroup(pageList).map((inputObject, index) => {
            const divider = Number(inputObject.group) !== Number(prevGroup);
            prevGroup = inputObject.group;
            return chooseInputElement(
              inputObject,
              register,
              errors,
              handleInputChange,
              index,
              divider,
              changeAmount
            );
          })}

          {messageDiv}
          {summaryDiv}

          <div className="flex flex-row justify-between">
            <button
              className={`flex items-center ${previousHidden} m-2 cursor-pointer`}
              onClick={(e) => changePage(e, currentPage - 1)}
            >
              <FcPrevious
                className={`${iconStyles}`}
                size={20}
                color={`#009ece`}
              />
              <span className="text-brand-blue">
                <FormattedMessage id="Form.back_button" />
              </span>
            </button>
            <button
              className={`${buttonColors} py-2 px-4 lg:m-5 m-2 w-auto rounded ${submitHidden}`}
              type="submit"
            >
              <FormattedMessage id={buttonInfo.format_id} />
            </button>
            <button
              className={`flex items-center ${continueHidden} m-2 cursor-pointer`}
              onClick={(e) => changePage(e, currentPage + 1)}
            >
              <span className="text-brand-blue">
                <FormattedMessage id="Form.continue_button" />
              </span>
              <FcNext className={`${iconStyles}`} size={20} color={`#009ece`} />
            </button>
          </div>
        </form>
      </div>
      <div>
        <ProgressBar
          steps={pages}
          current={currentPage}
          allowed={highestVisited}
          setPage={changePage}
        />
      </div>
    </>
  );
};

export default FormContainer;
