import React from 'react';
import { FormattedMessage } from 'react-intl';
import { AnswerObject} from '../../types/types';
import { InfoCard } from '../summary/InfoCard';

interface AlertDialogProps {
    message: string
    styles?: string
    alertAction: () => void
    cancelAction?: () => void
    summary?: AnswerObject[][],
}

export const AlertDialog: React.FC<AlertDialogProps> = ({ message, styles, alertAction, summary, cancelAction }) => {

  const style = styles !== undefined ? styles : 'container'

  const summaryDiv = summary ? summary.map((info, index) => {
    const title = index === 0 ? 'Summary.Info_Header' : 'Summary.Participant_Header'
  return <InfoCard key={index} data={info} title={title}/>
})
 : null

 const buttonFlex = cancelAction ? 'flex justify-center' : ''

 const cancelButtonDiv = cancelAction ? 
 <button 
  className='bg-blue-400 hover:bg-brand-blue text-brand-white py-2 px-4 m-5 w-auto rounded'
  onClick={() => cancelAction()}>
  <FormattedMessage id='AlertDialog.cancel_button'/>
</button>
: null

  const header = message === 'UNRESERVE FAIL' ?                         <FormattedMessage id='Form.Error_Unreserve_Header' /> :
                    message === 'RESERVATION FAIL' ?                    <FormattedMessage id='Form.Error_Reserve_Header' /> :
                    message === 'EVENT FULL, NO REGISTRATION ALLOWED' ? <FormattedMessage id='Form.Error_EventFull_Header' /> :
                    message === 'TOO LARGE GROUP' ?                     <FormattedMessage id='Form.Error_EventLargeGroup_Header' /> :
                    message === 'REGISTRATION TO QUEUE'  ?              <FormattedMessage id='Form.Error_EventQueue_Header' /> :
                    message === 'RESERVATION EXPIRE' ?                  <FormattedMessage id='Form.Error.Reservation_Expire_Header' /> :
                    message === 'ONGOING MAINTENANCE' ?                 <FormattedMessage id='Form.MaintenanceMode_Header' /> :
                    message === 'EXPIRED MODIFY TOKEN' ?                <FormattedMessage id='Form.ExpiredTokenModify_Header' /> :
                    message === 'EXPIRED CANCEL TOKEN' ?                <FormattedMessage id='Form.ExpiredTokenCancel_Header' /> :
                    message === 'RESERVATION CANCEL' ?                  <FormattedMessage id='Form.ReservationCancel_Header' /> :
                                                                        <FormattedMessage id='Form.Error.Generic_Header' />

  const paragraph = message === 'UNRESERVE FAIL' ?                      <FormattedMessage id='Form.Error_Unreserve' /> :
                    message === 'RESERVATION FAIL' ?                    <FormattedMessage id='Form.Error_Reserve' /> :
                    message === 'EVENT FULL, NO REGISTRATION ALLOWED' ? <FormattedMessage id='Form.Error_EventFull' /> :
                    message === 'TOO LARGE GROUP' ?                     <FormattedMessage id='Form.Error_EventLargeGroup' /> :
                    message === 'REGISTRATION TO QUEUE' ?               <FormattedMessage id='Form.Error_EventQueue' /> :
                    message === 'RESERVATION EXPIRE' ?                  <FormattedMessage id='Form.Error.Reservation_Expire' /> :
                    message === 'ONGOING MAINTENANCE' ?                 <FormattedMessage id='Form.MaintenanceMode' /> :
                    message === 'EXPIRED MODIFY TOKEN' ?                <FormattedMessage id='Form.ExpiredTokenModify' /> :
                    message === 'RESERVATION CANCEL' ?                  <FormattedMessage id='Form.ReservationCancel' /> :
                    message === 'EXPIRED CANCEL TOKEN' ?                <FormattedMessage id='Form.ExpiredTokenCancel' /> :
                                                                        <FormattedMessage id='Form.Error.Generic' />
                                                                    
  return (
    <div className={style}>
            <div className='flex flex-col bg-brand-white rounded-b pt-5 text-center'>
                <span className='text-brand-dark_blue uppercase font-bold m-4'>{header}</span>
                <span className='text-brand-dark_blue text-center mb-4'>{paragraph}</span>
                {summaryDiv}
                <div  className={`pb-2 ${buttonFlex}`}>
                  <button 
                      className='bg-blue-400 hover:bg-brand-blue text-brand-white py-2 px-4 m-5 w-auto rounded'
                      onClick={() => alertAction()}>
                      <FormattedMessage id='AlertDialog.confirm_button'/>
                  </button>
                  {cancelButtonDiv}
                </div>
        </div>
    </div>
  );
}

export default AlertDialog;
