import axios from 'axios'
import ConsoleHelper from '../utils/logger';
import * as config from './config';


axios.interceptors.request.use(x => {
  ConsoleHelper(x)
  return x
})


const reserve = async (amount: number, subEventId: string) => {
  const url = `${config.baseUrl}/ereg/reserve/${subEventId}/${amount}`
  return await axios({url})
}


const unreserve = async (obj: {subEventId: string, uuids: string[]}) => {
  const url = `${config.baseUrl}/ereg/reserve`
  return await axios({method: 'DELETE', url, data: obj})
}


const register = async (data: any) => {
  const url = `${config.baseUrl}/ereg/register`
  return await axios({method: 'POST', url, data})
}


const registerService = {
  reserve,
  unreserve,
  register
}

export default registerService
