import React from "react";
import { AiFillCaretDown } from "react-icons/ai";
import { IconWrapper } from "../utils/IconWrapper";
import { RepeatableHeader } from "./divs/RepatableHeader";
import { LabelContainer } from "./labels/LabelContainer";
import { ErrorDiv } from "./divs/ErrorDiv";
import { RepeatableButtons } from "./buttons/RepeatableButtons";

interface FormSelectProps {
  id: string;
  options?: string[];
  question: string;
  type?: string;
  itemAttribute?: string;
  value?: string;
  divider?: string;
  containerStyle?: string;
  inputStyle?: string;
  divStyle?: string;
  labelStyle?: string;
  repeatable?: boolean;
  dividerHeaderStyle?: string;
  register?: any;
  errors?: Record<string, Object>;
  link?: string | boolean;
  cursiveLabel?: string;
  repeatableHeader?: string;
  repeatableKey?: { key: string; last: boolean; amount: number };
  index?: number;
  overlayId?: string;
  overlayToggle?: (id: string) => void;
  changeAmount?: (key: string, direction: number) => void;
  validate: (id: string) => void;
  handleInputChange: (
    event: React.ChangeEvent<HTMLSelectElement>,
    id: string,
    type?: string
  ) => void;
}

export const FormSelect: React.FC<FormSelectProps> = ({
  id,
  options,
  handleInputChange,
  question,
  value,
  containerStyle,
  divider,
  errors,
  itemAttribute,
  repeatableHeader,
  validate,
  inputStyle,
  divStyle,
  labelStyle,
  dividerHeaderStyle,
  repeatable,
  register,
  link,
  cursiveLabel,
  repeatableKey,
  changeAmount,
  index,
  overlayToggle,
  overlayId,
}) => {
  const styles = inputStyle
    ? inputStyle
    : "border border-gray-300 rounded-lg h-10 m-4 leading-normal cursor-pointer";

  const stylesInner = inputStyle
    ? inputStyle
    : "font-semibold text-brand-dark_blue focus:outline-none focus:bg-blue-100 focus:shadow-inner h-full w-full rounded-lg pl-5 leading-normal cursor-pointer";

  const divStyles = divStyle
    ? divStyle
    : "flex flex-col mb-4 xl:w-11/12 xl:m-auto";

  const participantId = repeatable ? id.split("_")[1] : ""; //expected id is 'BLABLABLA_2' => 2

  const dividerHeaderStyles = dividerHeaderStyle
    ? dividerHeaderStyle
    : "m-2 ml-4 uppercase font-bold text-lg text-brand-dark_blue";

  const dividerStyles = divider ? divider : "";
  const containerStyles = containerStyle ? containerStyle : "";

  return (
    <div
      className={`${divStyles} ${containerStyles} ${dividerStyles}`}
      tabIndex={index}
    >
      <RepeatableHeader
        divider={divider}
        repeatable={repeatable}
        label={`${repeatableHeader} ${participantId}`}
        style={dividerHeaderStyles}
      />
      <LabelContainer
        link={link}
        question={question}
        labelStyle={labelStyle}
        register={register}
        cursiveLabel={cursiveLabel}
        overlayToggle={overlayToggle}
        overlayId={overlayId}
      />
      <div className={`relative ${styles}`}>
        <select
          className={`appearance-none ${stylesInner}`}
          id={id}
          value={value}
          onChange={(e) => handleInputChange(e, id, itemAttribute)}
          name={id}
          ref={register}
          onBlur={validate ? () => validate(id) : null}
        >
          {options?.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
        <IconWrapper styles="text-brand-dark_blue absolute right-0 mr-5 mt-2">
          <AiFillCaretDown size={20} />
        </IconWrapper>
      </div>
      <ErrorDiv errors={errors} id={id} />
      <RepeatableButtons
        repeatableKey={repeatableKey}
        changeAmount={changeAmount}
        repeatableHeader={repeatableHeader}
      />
    </div>
  );
};

export default FormSelect;
