import { toggleReducer } from './toggleReducer'
import { formReducer } from './formReducer'
import { loadingReducer } from './loadingReducer'
import { combineReducers } from 'redux-loop';
import { errorReducer } from './errorReducer'
import { registerReducer } from './registerReducer';
import { reservationReducer } from './reservationReducer';

const rootReducer = combineReducers({
    formState: formReducer,
    toggleState: toggleReducer,
    loadingState: loadingReducer,
    errorState: errorReducer,
    registerState: registerReducer,
    reservationState: reservationReducer
})

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;