import React from "react";
import { HelpIcon } from "./HelpIcon";
import { RequiredAsterisk } from "./RequiredAsterisk";

interface Props {
  labelStyle?: string;
  register?: any;
  question: string;
  overlayId?: string;
  overlayToggle?: (id: string) => void;
}

export const LabelWithHelpIcon: React.FC<Props> = ({
  labelStyle,
  question,
  overlayId,
  overlayToggle,
  register,
}) => {
  const labelStyles = labelStyle
    ? labelStyle
    : "mb-2 ml-2 uppercase font-bold text-lg text-brand-dark_blue";

  const mouseHoverTrigger = (e: any) => {
    overlayToggle(overlayId);
  };

  return (
    <div className="flex relative">
      <span className={`${labelStyles}`}>
        {question}
        <RequiredAsterisk register={register} />
      </span>
      <HelpIcon mouseHoverTrigger={mouseHoverTrigger} overlayId={overlayId} />
    </div>
  );
};
